import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { apiGet } from "utils/api";
import { getDateFormatAsDateMonthYear } from "utils/dates";

const NewsSidebar = () => {
  const navigate = useNavigate();
  const [newsItem, setNewsItem] = useState("");
  const getAllNews = async () => {
    const res = await apiGet(`news`);
    const data = await res?.data;
    return data;
  };

  useEffect(() => {
    if (!newsItem) {
      getAllNews().then((data) => setNewsItem(data.news));
    }
  }, []);

  const handleNavigate = (id) => {
    navigate(`/single-news/${id}`);
    window.location.reload(true);
  };
  return (
    <div>
      <div className="sidebar-widget popular-posts ">
        <div className="sidebar-title">
          <h6>Recent News</h6>
        </div>
        {newsItem &&
          newsItem?.slice(0, 6).map((item, idx) => {
            return (
              <article className="post" key={idx}>
                <div
                  className="text"
                  onClick={() => handleNavigate(item._id)}
                  key={idx}
                >
                  <h5>{item.title}</h5>
                </div>
                <div className="post-info">
                  {getDateFormatAsDateMonthYear(item.date)}
                </div>
              </article>
            );
          })}
      </div>
    </div>
  );
};

export default NewsSidebar;
