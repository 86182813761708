import React from "react";

const TopHeader = () => {
  return (
    <div className="  h-16 bg-base-color">
      <div className=" h-full flex justify-around items-center text-white">
        <div className=" hidden lg:block text-xs uppercase">
          Welcome to Sheikh Tube Mills Ltd.
        </div>
        <div className=" flex items-center gap-4">
          <a
            href="https://www.facebook.com/sheikhtubemills"
            target="_blank"
            rel="noreferrer"
          >
            <i className="fab fa-facebook-f cursor-pointer"></i>
          </a>
          <a
            href="https://twitter.com/sheikhtubemills"
            target="_blank"
            rel="noreferrer"
          >
            <i class="fa-brands fa-x-twitter"></i>
          </a>
          <a
            href="https://www.youtube.com/@sheikhtubemillsltd2374"
            target="_blank"
            rel="noreferrer"
          >
            <i className="fab fa-youtube cursor-pointer"></i>
          </a>
          <a
            href="https://www.instagram.com/sheikhtubemills/"
            target="_blank"
            rel="noreferrer"
          >
            <i className="fab fa-instagram cursor-pointer"></i>
          </a>
          {/* <i className="fab fa-google-plus-g cursor-pointer"></i> */}
        </div>
      </div>
    </div>
  );
};

export default TopHeader;
