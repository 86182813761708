import { DateTime } from "luxon";

export const convertToDateString = (date) => {
  const year = new Date(date).getFullYear();
  const month = new Date(date).getMonth().toString();
  const day = new Date(date).getDay().toString();
  const output = `${year}-${month.length === 1 ? "0" + month : month}-${
    day.length === 1 ? "0" + day : day
  }`;
  return output;
};

export const convertToDate = (date) => {
  return new Date(date);
};

export const checkDateDiff = (start, end) => {
  const diff = end - start;
  return diff / 1000;
};

export const getReadableStringDate = (date, skipDay) => {
  const output = new Date(date).toDateString().split(" ");
  const dateToYear = `${output[1]} ${output[2]}, ${output[3]}`;
  const str = skipDay ? dateToYear : `${output[0]}, ${dateToYear}`;

  return str;
};

export const getDateFormatAsDateMonthYear = (date) => {
  const output = new Date(date).toDateString().split(" ");
  const dateToYear = `${output[2]} ${output[1]} ${output[3]}`;
  const str = dateToYear;

  return str;
};

export const dateDiff = (start, end) => {
  const d1 = DateTime.fromISO(new Date(start).toISOString());
  const d2 = DateTime.fromISO(new Date(end).toISOString());
  const diff = d2.diff(d1, ["years", "months", "days", "hours"]);
  return diff.toObject();
};
