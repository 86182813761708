import React from "react";

const PrimaryBtn = ({ btnName }) => {
  return (
    <button className="group relative p-4 w-48 overflow-hidden   border text-lg shadow">
      <div className="absolute inset-0 w-3 bg-base-color transition-all duration-[250ms] ease-out group-hover:w-full" />
      <span className="relative text-white group-hover:text-white">
        {btnName}
      </span>
    </button>
  );
};

export default PrimaryBtn;
