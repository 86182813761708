import ScrollToTop from "components/scroll-to-top";
import React, { useEffect, useState } from "react";
import { apiGet } from "utils/api";
import FaqList from "./faq-list";

const Faq = () => {
  ScrollToTop();
  const [faq, setfaq] = useState();

  const fetchData = async () => {
    const res = await apiGet("faq").catch((err) => console.log(err));

    const data = res.data;
    console.log(data);
    return data;
  };

  useEffect(() => {
    fetchData().then((data) => {
      setfaq(data.allItem);
    });
  }, []);
  return (
    <div>
      <section className="faq-page-section">
        <div className="auto-container w-full">
          <p className=" text-center  lg:text-start font-semibold text-xl lg:text-3xl uppercase text-black">
            <span className=" border-b-4 border-base-color">Frequently </span>{" "}
            Ask Questions
          </p>
          {/* faq Form */}
          <div className="faq-search-box">
            <form>
              <div className="form-group">
                <input
                  type="search"
                  name="search-field"
                  placeholder="Search Your Answer"
                  className=" appearance-none outline-none"
                  required
                />
                <button type="submit">
                  <span className="icon fa fa-search" />
                </button>
              </div>
            </form>
          </div>
          {/*Accordion Box*/}
          <ul className="accordion-box">
            {/*Block*/}
            {faq &&
              faq.map((item, index) => (
                <FaqList title={item.title} description={item.description} />
              ))}
          </ul>
          {/*End Accordion Box*/}
        </div>
      </section>
    </div>
  );
};

export default Faq;
