import PageHeader from "components/page-header";
import React, { useEffect, useState } from "react";
import banner from "assets/banner/banner.png";
import { useNavigate } from "react-router-dom";
import { apiGet } from "utils/api";
import ScrollToTop from "components/scroll-to-top";

const Career = () => {
  ScrollToTop();
  const [careers, setcareers] = useState();
  const navigate = useNavigate();
  const fetchData = async () => {
    const res = await apiGet("career").catch((err) => console.log(err));

    const data = await res.data;

    return data;
  };

  useEffect(() => {
    fetchData().then((data) => {
      setcareers(data.allItems);
    });
  }, []);

  const handleView = (id) => {
    navigate(`/careerDetail/${id}`);
  };
  return (
    <div>
      <div>
        <PageHeader banner={banner} pageName="Career" />
      </div>
      <div className="mt-10">
        <section className="p-[10%]">
          <div className="auto-container">
            <div className=" flex flex-col justify-center p-4">
              <table className="table-auto border">
                <thead className="bg-[#F4F6FA] ">
                  <tr className="">
                    <td className="p-4">Job Title</td>
                    <td>Job Requirement</td>
                    <td>Option</td>
                  </tr>
                </thead>
                {careers &&
                  careers.map((item, index) => (
                    <tbody className="p-4 ">
                      <tr className="p-4 border-t-2">
                        <td className="p-4 ">{item.title}</td>
                        <td className="p-4">
                          {item.jobRequirment.substring(0, 50) + "...."}
                        </td>
                        <td className="p-4">
                          <button
                            className="bg-base-color p-3 rounded text-white "
                            onClick={() => handleView(item._id)}
                            target="_blank"
                          >
                            View
                          </button>
                        </td>
                      </tr>
                    </tbody>
                  ))}
              </table>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default Career;
