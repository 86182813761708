import React from "react";
import { useState } from "react";

const FaqList = ({ key, title, description }) => {
  const [active, setActive] = useState(false);

  const handleClick = () => {
    setActive(!active);
  };
  return (
    <>
      <li className="accordion block" onClick={handleClick}>
        <div className={!active ? "acc-btn" : "acc-btn active"}>
          <div className="icon-outer">
            <span className="icon icon-plus fa fa-arrow-right" />{" "}
            <span className="icon icon-minus fa fa-arrow-down" />
          </div>{" "}
          <span className="icon-inner flaticon-pie-chart-3" /> {title}{" "}
        </div>
        {active && (
          <div className={"acc-content current"}>
            <div className="content">
              <p> {description} </p>
            </div>
          </div>
        )}
      </li>
    </>
  );
};

export default FaqList;
