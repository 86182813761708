import Footer from "./components/footer";
import Header from "components/navbars/main-header";
import TopHeader from "components/navbars/top-header";
import Home from "pages/home";
import { Route, Routes } from "react-router-dom";
import Products from "pages/products";
import About from "pages/about";
import ContactUs from "pages/contactus";
import NewsPreview from "pages/news/news-preview";
import News from "pages/news";
import PipePage from "pages/orders/pipe";
import Scrap from "pages/orders/scrap";
import Faq from "pages/faq";
import Testimonial from "pages/testimonial";
import Consultation from "pages/consultation";
import Agent from "pages/Agent";
import Career from "pages/career";
import CareerDetails from "pages/career/career-details";
import TruckRent from "pages/truckRent";
import DeliveryTracking from "pages/deliveryTracking/index";
import DeliveryDetails from "pages/deliveryTracking/deliveryDetails";
import { ToastProvider } from "components/toast";

const App = () => {
  return (
    <div>
      <ToastProvider>
        <TopHeader />
        <Header />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/products" element={<Products />} />
          <Route path="/about-us" element={<About />} />
          <Route path="/contact-us" element={<ContactUs />} />
          <Route path="/news" element={<News />} />
          <Route path="/single-news/:id" element={<NewsPreview />} />
          <Route path="/pipe" element={<PipePage />} />
          <Route path="/scrap" element={<Scrap />} />
          <Route path="/faq" element={<Faq />} />
          <Route path="/testimonials" element={<Testimonial />} />
          <Route path="/free-consultation" element={<Consultation />} />
          <Route path="/become-agent" element={<Agent />} />
          <Route path="/career" element={<Career />} />
          <Route path="/careerDetail/:id" element={<CareerDetails />} />
          <Route path="/truck-rent" element={<TruckRent />} />
          <Route path="/delivery-tracking" element={<DeliveryTracking />} />
          <Route path="/delivery-details/:id" element={<DeliveryDetails />} />
        </Routes>
        <Footer />
      </ToastProvider>
    </div>
  );
};

export default App;
