import PageHeader from "components/page-header";
import React, { useState } from "react";
import { apiPost } from "utils/api";
import banner from "assets/banner/banner.png";
import { useToast } from "components/toast";

const PipePage = () => {
  const [inputs, setInputs] = useState({
    name: "",
    email: "",
    phone: "",
    companyName: "",
    companyAddress: "",
    cityName: "",
    productName: "",
    productType: "",
    thickness: "",
    productQuantity: "",
    destinationAddress: "",
    message: "",
  });
  const { add } = useToast();

  const handleChange = (e) => {
    setInputs((pervState) => ({
      ...pervState,
      [e.target.name]: e.target.value,
    }));
  };

  const sendRequest = async () => {
    await apiPost("pipe/addOrder", {
      name: inputs.name,
      email: inputs.email,
      phone: inputs.phone,
      companyName: inputs.companyName,
      companyAddress: inputs.companyAddress,
      cityName: inputs.cityName,
      productName: inputs.productName,
      productType: inputs.productType,
      thickness: inputs.thickness,
      productQuantity: inputs.productQuantity,
      destinationAddress: inputs.destinationAddress,
      message: inputs.message,
    }).catch((err) => console.log(err));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(inputs);
    sendRequest()
      .then(() => {
        add({
          type: "success",
          message: "Your order placed scuccesfully",
        });
      })

      .then(() => {
        setInputs({
          name: "",
          email: "",
          phone: "",
          companyName: "",
          companyAddress: "",
          cityName: "",
          productName: "",
          productType: "",
          thickness: "",
          productQuantity: "",
          destinationAddress: "",
          message: "",
        });
      });
  };

  return (
    <div>
      <div>
        <PageHeader banner={banner} pageName="Pipe order" />
      </div>
      <div className=" mt-10  ">
        <section className="case-section alternate gallery-page-case ">
          <div className=" flex  justify-center">
            <div className=" w-full lg:px-[20%] ">
              {/*Form with header*/}
              <form onSubmit={handleSubmit} name="accountFrom" id="accountFrom">
                <div className="card border-primary rounded-0">
                  <div className="w-full  p-0">
                    <div
                      className="bg-info text-white text-center py-2 bg-[#13b5ea]"
                      style={{ backgroundColor: " !important" }}
                    >
                      <h3 className=" font-semibold text-xl">
                        <i className="fa fa-envelope mr-1 text-xl" /> Pre Order
                        Form
                      </h3>
                      <p className=" mt-3">You Can Pre Order From Here</p>
                    </div>
                  </div>
                  <div className=" border border-base-color  rounded-b p-3">
                    {/*Body*/}
                    <div className="border border-base-color rounded   p-4">
                      <div className="flex gap-2 items-center">
                        <div className=" ">
                          <div className="input-group-text">
                            <i className="fa fa-user text-xl  text-base-color" />
                          </div>
                        </div>
                        <input
                          type="text"
                          className=" appearance-none outline-none w-full"
                          id="name"
                          name="name"
                          value={inputs.name}
                          onChange={handleChange}
                          placeholder="Name"
                          required
                        />
                      </div>
                    </div>
                    <div className="border border-base-color rounded  mt-2  p-4">
                      <div className="flex gap-2 items-center">
                        <div className="">
                          <div className="">
                            <i className="fa fa-envelope  text-xl text-base-color" />
                          </div>
                        </div>
                        <input
                          type="email"
                          className=" appearance-none outline-none w-full"
                          id="email"
                          name="email"
                          value={inputs.email}
                          onChange={handleChange}
                          placeholder="Email Address"
                          required
                        />
                      </div>
                    </div>
                    <div className="border border-base-color rounded  mt-2  p-4">
                      <div className="flex gap-2 items-center">
                        <div className="p-1">
                          <div className="input-group-text">
                            <i className="fa fa-phone  text-xl text-base-color" />
                          </div>
                        </div>
                        <input
                          type="text"
                          className="appearance-none outline-none w-full"
                          id="phone"
                          name="phone"
                          value={inputs.phone}
                          onChange={handleChange}
                          placeholder="Phone Number"
                          required
                        />
                      </div>
                    </div>
                    <div className="border border-base-color rounded  mt-2  p-4">
                      <div className="flex gap-2 items-center">
                        <div className="p-1">
                          <div className="input-group-text">
                            <i className="fa fa-list text-xl text-base-color" />
                          </div>
                        </div>
                        <input
                          type="text"
                          className="appearance-none outline-none w-full"
                          id="company_name"
                          name="companyName"
                          value={inputs.companyName}
                          onChange={handleChange}
                          placeholder="Company Name"
                          required
                        />
                      </div>
                    </div>
                    <div className="border border-base-color rounded  mt-2  p-4">
                      <div className="flex gap-2 items-center">
                        <div className="p-1">
                          <div className="input-group-text">
                            <i className="fas fa-map-pin text-xl text-base-color" />
                          </div>
                        </div>
                        <input
                          type="text"
                          className="appearance-none outline-none w-full"
                          id="company_address"
                          name="companyAddress"
                          value={inputs.companyAddress}
                          onChange={handleChange}
                          placeholder="Company Address"
                          required
                        />
                      </div>
                    </div>
                    <div className="border border-base-color rounded  mt-2  p-4">
                      <div className="flex gap-2 items-center">
                        <div className="p-1">
                          <div className="input-group-text">
                            <i className="fa fa-user text-xl text-base-color" />
                          </div>
                        </div>
                        <input
                          type="text"
                          className="appearance-none outline-none w-full"
                          id="city_name"
                          name="cityName"
                          value={inputs.cityName}
                          onChange={handleChange}
                          placeholder="City Name"
                          required
                        />
                      </div>
                    </div>
                    <div className="border border-base-color rounded  mt-2  p-4">
                      <div className="flex gap-2 items-center">
                        <div className="p-1">
                          <div className="input-group-text">
                            <i className="fa fa-briefcase text-xl text-base-color" />
                          </div>
                        </div>
                        <input
                          type="text"
                          className="appearance-none outline-none w-full"
                          id="product_name"
                          name="productName"
                          value={inputs.productName}
                          onChange={handleChange}
                          placeholder="Product Name"
                          required
                        />
                      </div>
                    </div>
                    <div className="border border-base-color rounded  mt-2  p-4">
                      <div className="flex gap-2 items-center">
                        <div className="p-1">
                          <div className="input-group-text">
                            <i className="fa fa-briefcase text-xl text-base-color" />
                          </div>
                        </div>
                        <input
                          type="text"
                          className="appearance-none outline-none w-full"
                          id="product_type"
                          name="productType"
                          value={inputs.productType}
                          onChange={handleChange}
                          placeholder="Product Type"
                          required
                        />
                      </div>
                    </div>
                    <div className="border border-base-color rounded  mt-2  p-4">
                      <div className="flex gap-2 items-center">
                        <div className="p-1">
                          <div className="input-group-text">
                            <i className="fa fa-briefcase text-xl text-base-color" />
                          </div>
                        </div>
                        <input
                          type="text"
                          className="appearance-none outline-none w-full"
                          id="thickness"
                          name="thickness"
                          value={inputs.thickness}
                          onChange={handleChange}
                          placeholder="Thickness"
                          required
                        />
                      </div>
                    </div>
                    <div className="border border-base-color rounded  mt-2  p-4">
                      <div className="flex gap-2 items-center">
                        <div className="p-1">
                          <div className="input-group-text">
                            <i className="fa fa-calculator text-xl text-base-color" />
                          </div>
                        </div>
                        <input
                          type="text"
                          className="appearance-none outline-none w-full"
                          id="qty"
                          name="productQuantity"
                          value={inputs.productQuantity}
                          onChange={handleChange}
                          placeholder="Product Quantity"
                          required
                        />
                      </div>
                    </div>
                    <div className="border border-base-color rounded  mt-2  p-4">
                      <div className="flex gap-2 items-center">
                        <div className="p-1">
                          <div className="input-group-text">
                            <i className=" fas fa-map-pin text-xl text-base-color" />
                          </div>
                        </div>
                        <input
                          type="text"
                          className="appearance-none outline-none w-full"
                          id="destination_address"
                          name="destinationAddress"
                          value={inputs.destinationAddress}
                          onChange={handleChange}
                          placeholder="Destination Address"
                          required
                        />
                      </div>
                    </div>
                    <div className="border border-base-color rounded  mt-2  p-4">
                      <div className="flex gap-2 items-center">
                        <div className="p-1">
                          <div className="input-group-text">
                            <i className="fa fa-comment text-xl text-base-color" />
                          </div>
                        </div>
                        <textarea
                          className="appearance-none outline-none w-full"
                          name="message"
                          value={inputs.message}
                          onChange={handleChange}
                          placeholder="Enter Your Message"
                          required
                          defaultValue={""}
                        />
                      </div>
                    </div>
                    <div className="text-center text-white font-semibold mt-3 cursor-pointer ">
                      <input
                        type="submit"
                        defaultValue="Submit"
                        className="bg-base-color p-3 w-1/4 rounded text-whtie hover:bg-black hover:duration-200 cursor-pointer "
                        style={{ backgroundColor: "#13b5ea!important" }}
                      />
                    </div>
                  </div>
                </div>
              </form>
              {/*Form with header*/}
            </div>
          </div>
          {/*Lower Box*/}
          <div className="lower-box">
            <div className="case-text">
              Want to make new project?{" "}
              <a href="{{url('/contact-us')}}">contact with us.</a> Our expert
              is ready to help you
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default PipePage;
