import React, { useState } from "react";
import PageHeader from "components/page-header";
import banner from "assets/banner/banner.png";
import { apiPost } from "utils/api";
import { Link } from "react-router-dom";
import { useToast } from "components/toast";
const Scrap = () => {
  const [inputs, setinputs] = useState({
    name: "",
    email: "",
    phone: "",
    companyName: "",
    companyAddress: "",
    quantity: "",
    scrapType: "",
    destinationAddress: "",
    message: "",
  });

  const { add } = useToast();

  const handleChange = (e) => {
    setinputs((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const sendRequest = async () => {
    const res = await apiPost("scrap/addScrap", {
      name: inputs.name,
      email: inputs.email,
      phone: inputs.phone,
      companyName: inputs.companyName,
      companyAddress: inputs.companyAddress,
      quantity: inputs.quantity,
      scrapType: inputs.scrapType,
      destinationAddress: inputs.destinationAddress,
      message: inputs.message,
    }).catch((err) =>
      add({
        type: "warning",
        message: err.message,
      })
    );
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(inputs);
    sendRequest()
      .then(() => {
        add({
          type: "success",
          message: "Your order placed scuccesfully",
        });
      })
      .then(() => {
        setinputs({
          name: "",
          email: "",
          phone: "",
          companyName: "",
          companyAddress: "",
          quantity: "",
          scrapType: "",
          destinationAddress: "",
          message: "",
        });
      });
  };
  return (
    <div>
      <div>
        <PageHeader banner={banner} pageName="Scrap order" />
      </div>
      <div className=" w-full lg:px-[20%] mt-10">
        <section className="case-section alternate gallery-page-case">
          <div className="row justify-content-center">
            <div className="col-12 col-md-8 col-lg-10 pb-5">
              {/*Form with header*/}
              <form onSubmit={handleSubmit} name="accountFrom" id="accountFrom">
                <div className="card border-primary rounded-0">
                  <div className="card-header p-0">
                    <div
                      className="bg-info text-white  text-center py-2 bg-[#13b5ea]"
                      style={{ backgroundColor: "#13b5ea!important" }}
                    >
                      <h3 className="font-semibold text-xl">
                        <i className="fa fa-envelope" /> Scrap
                      </h3>
                    </div>
                  </div>
                  <div className=" border border-base-color  rounded-b p-3">
                    {/*Body*/}
                    <div className="border border-base-color rounded   p-4 mt-2">
                      <div className="flex gap-2 items-center ">
                        <div className="flex gap-2 items-center-prepend">
                          <div className="flex gap-2 items-center-text">
                            <i className="fa fa-user text-xl  text-base-color" />
                          </div>
                        </div>
                        <input
                          type="text"
                          className="appearance-none outline-none w-full"
                          id="name"
                          name="name"
                          value={inputs.name}
                          onChange={handleChange}
                          placeholder="Name"
                          required
                        />
                      </div>
                    </div>
                    <div className="border border-base-color rounded   p-4 mt-2">
                      <div className="flex gap-2 items-center">
                        <div className="flex gap-2 items-center-prepend">
                          <div className="flex gap-2 items-center-text">
                            <i className="fa fa-envelope text-xl  text-base-color" />
                          </div>
                        </div>
                        <input
                          type="email"
                          className="appearance-none outline-none w-full"
                          id="email"
                          name="email"
                          value={inputs.email}
                          onChange={handleChange}
                          placeholder="Email Address"
                          required
                        />
                      </div>
                    </div>
                    <div className="border border-base-color rounded   p-4 mt-2">
                      <div className="flex gap-2 items-center">
                        <div className="flex gap-2 items-center-prepend">
                          <div className="flex gap-2 items-center-text">
                            <i className="fa fa-phone text-xl  text-base-color" />
                          </div>
                        </div>
                        <input
                          type="text"
                          className="appearance-none outline-none w-full"
                          id="phone"
                          name="phone"
                          value={inputs.phone}
                          onChange={handleChange}
                          placeholder="Phone Number"
                          required
                        />
                      </div>
                    </div>
                    <div className="border border-base-color rounded   p-4 mt-2">
                      <div className="flex gap-2 items-center">
                        <div className="flex gap-2 items-center-prepend">
                          <div className="flex gap-2 items-center-text">
                            <i className="fa fa-list text-xl  text-base-color" />
                          </div>
                        </div>
                        <input
                          type="text"
                          className="appearance-none outline-none w-full"
                          id="company_name"
                          name="companyName"
                          value={inputs.companyName}
                          onChange={handleChange}
                          placeholder="Company Name"
                          required
                        />
                      </div>
                    </div>
                    <div className="border border-base-color rounded   p-4 mt-2">
                      <div className="flex gap-2 items-center">
                        <div className="flex gap-2 items-center-prepend">
                          <div className="flex gap-2 items-center-text">
                            <i className="fas fa-map-pin text-xl  text-base-color" />
                          </div>
                        </div>
                        <input
                          type="text"
                          className="appearance-none outline-none w-full"
                          id="company_address"
                          name="companyAddress"
                          value={inputs.companyAddress}
                          onChange={handleChange}
                          placeholder="Company Address"
                          required
                        />
                      </div>
                    </div>

                    <div className="border border-base-color rounded   p-4 mt-2">
                      <div className="flex gap-2 items-center">
                        <div className="flex gap-2 items-center-prepend">
                          <div className="flex gap-2 items-center-text">
                            <i className="fa fa-calculator text-xl  text-base-color" />
                          </div>
                        </div>
                        <input
                          type="text"
                          className="appearance-none outline-none w-full"
                          id="qty"
                          name="quantity"
                          value={inputs.quantity}
                          onChange={handleChange}
                          placeholder="Quantity"
                          required
                        />
                      </div>
                    </div>
                    <div className="border border-base-color rounded   p-4 mt-2">
                      <div className="flex gap-2 items-center">
                        <div className="flex gap-2 items-center-prepend">
                          <div className="flex gap-2 items-center-text">
                            <i className="fa fa-briefcase text-xl  text-base-color" />
                          </div>
                        </div>
                        <input
                          type="text"
                          className="appearance-none outline-none w-full"
                          id="scrap_type"
                          name="scrapType"
                          value={inputs.scrapType}
                          onChange={handleChange}
                          placeholder="Scrap Type"
                          required
                        />
                      </div>
                    </div>
                    <div className="border border-base-color rounded   p-4 mt-2">
                      <div className="flex gap-2 items-center">
                        <div className="flex gap-2 items-center-prepend">
                          <div className="flex gap-2 items-center-text">
                            <i className="fas fa-map-pin text-xl  text-base-color" />
                          </div>
                        </div>
                        <input
                          type="text"
                          className="appearance-none outline-none w-full"
                          id="destination_address"
                          name="destinationAddress"
                          value={inputs.destinationAddress}
                          onChange={handleChange}
                          placeholder="Destination Address"
                          required
                        />
                      </div>
                    </div>
                    <div className="border border-base-color rounded   p-4 mt-2">
                      <div className="flex gap-2 items-center">
                        <div className="flex gap-2 items-center-prepend">
                          <div className="flex gap-2 items-center-text">
                            <i className="fa fa-comment text-xl  text-base-color" />
                          </div>
                        </div>
                        <textarea
                          className="appearance-none outline-none w-full"
                          name="message"
                          placeholder="Enter Your Message"
                          value={inputs.message}
                          onChange={handleChange}
                          required
                          defaultValue={""}
                        />
                      </div>
                    </div>
                    <div className="text-center text-white font-semibold mt-3 cursor-pointer">
                      <input
                        type="submit"
                        defaultValue="Submit"
                        className="bg-base-color p-3 w-1/4 rounded text-whtie hover:bg-black hover:duration-200 cursor-pointer "
                        style={{ backgroundColor: "#13b5ea!important" }}
                      />
                    </div>
                  </div>
                </div>
              </form>
              {/*Form with header*/}
            </div>
          </div>
          {/*Lower Box*/}
          <div className="lower-box">
            <div className="case-text">
              Want to make new project?{" "}
              <Link to="/contact-us">contact with us.</Link> Our expert is ready
              to help you
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default Scrap;
