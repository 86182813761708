import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { apiGet } from "utils/api";
import { getDateFormatAsDateMonthYear } from "utils/dates";
import { staticURL } from "../../../utils/config";
import DOMPurify from "dompurify";
import NewsSidebar from "./news-sidebar";
import ScrollToTop from "components/scroll-to-top";

const NewsPreview = () => {
  const [datas, setDatas] = useState();
  const navigate = useNavigate();
  const { id } = useParams();
  ScrollToTop();

  const fetchData = async () => {
    const res = await apiGet(`news/${id}`);
    const data = await res.data;
    setDatas(data.item);
  };

  useEffect(() => {
    fetchData();
  }, []);
  return (
    <div>
      <div className=" flex flex-col lg:flex-row py-[5%] px-[5%] lg:px-[10%] gap-8">
        <div className="content-side  lg:w-[70%]">
          {/*Blog Single*/}
          <div className="blog-single">
            <div className="inner-box">
              <div className="image">
                <img
                  src={`${staticURL}/static/${datas?.image}`}
                  alt={datas?.title.substring(0, 2)}
                />
              </div>
              <div className="lower-content">
                <h6>{datas?.title}</h6>
                <ul className="post-meta">
                  <li>
                    <span className="icon fa fa-user" />
                    {datas?.author}
                  </li>
                  <li>
                    <span className="icon fa fa-calendar" />
                    {getDateFormatAsDateMonthYear(datas?.date)}
                  </li>
                </ul>
                <div
                  className="text"
                  dangerouslySetInnerHTML={{
                    __html: DOMPurify.sanitize(datas?.description),
                  }}
                ></div>
                {/*post-share-options*/}
                <div className="post-share-options clearfix">
                  <div className="pull-left">
                    <ul className="social-icon-three">
                      <li>
                        <a href="#">
                          <span className="fa fa-facebook" />
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <span className="fa fa-twitter" />
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <span className="fa fa-dribbble" />
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <span className="fa fa-linkedin" />
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div>
          <NewsSidebar />
        </div>
      </div>
    </div>
  );
};

export default NewsPreview;
