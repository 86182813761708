import React, { useState } from "react";
import { apiPost } from "../../utils/api";
import { useToast } from "components/toast";

const Quote = () => {
  const { add } = useToast();
  const [inputs, setInputs] = useState({
    assist: "",
    phone: "",
    name: "",
  });

  const handleChange = async (e) => {
    setInputs((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const sendRequest = async () => {
    const res = await apiPost("qoute/addQoute", {
      assist: inputs.assist,
      phone: inputs.phone,
      name: inputs.name,
    }).catch((err) => {
      add({
        type: "error",
        message: err.message,
      });
    });

    const data = await res.data;

    return data;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (inputs.assist === "" || inputs.phone === "" || inputs.name === "") {
      add({
        type: "error",
        message: "Please fill the form!",
      });
      return;
    } else {
      sendRequest()
        .then((res) => {
          if (res) {
            setInputs({
              assist: "",
              phone: "",
              name: "",
            });
            add({
              type: "success",
              message: "Your request has been submitted successfully",
            });
          }
        })
        .catch((error) => {
          add({
            type: "error",
            message: error.message,
          });
        });
    }
  };
  return (
    <div className="flex flex-col lg:flex-row justify-between">
      <div className=" lg:w-1/2">
        <iframe
          width="100%"
          height="450"
          frameborder="0"
          scrolling="no"
          marginheight="0"
          marginwidth="0"
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1570.8773005255648!2d91.84364845855787!3d22.308288434004545!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x30ad21ee3306a5df%3A0x3a9b2c10d3fcb065!2sSHEIKH%20TUBE%20MILLS%20LTD!5e0!3m2!1sen!2sus!4v1649184669084!5m2!1sen!2sus"
        ></iframe>
      </div>
      <div className=" lg:w-1/2 bg-black text-white  p-10 lg:p-20">
        <div>
          <p className=" font-bold text-3xl">Request for quote</p>
          <div className="border-b-4 w-16 border-white mt-4"></div>
          <p className=" mt-6">
            Please mail us your contact info. Our team will contact you shortly.
          </p>
        </div>
        <div>
          <div className=" flex flex-col lg:flex-row gap-2 mt-5">
            <div className=" py-4 px-5 bg-white rounded w-full">
              <input
                type="text"
                name="assist"
                className=" appearance-none border-none text-black w-full outline-none "
                placeholder="How to assist you*"
                value={inputs.assist}
                onChange={handleChange}
              />
            </div>
            <div className=" py-4 px-5 bg-white rounded w-full">
              <input
                type="number"
                className=" appearance-none border-none text-black w-full outline-none "
                placeholder="Phone number.*"
                name="phone"
                onChange={handleChange}
                value={inputs.phone}
              />
            </div>
          </div>

          <div className=" flex flex-col lg:flex-row gap-2 mt-2">
            <div className=" py-4 px-5 bg-white rounded w-full">
              <input
                type="text"
                name="name"
                className=" appearance-none border-none text-black w-full outline-none "
                placeholder="Your name*"
                onChange={handleChange}
                value={inputs.name}
              />
            </div>
            <div
              className=" py-4 px-5 bg-base-color rounded w-full flex items-center justify-center font-semibold text-lg cursor-pointer hover:bg-white hover:duration-700 hover:text-black "
              onClick={handleSubmit}
            >
              Submit Request
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Quote;
