import React from "react";
import hero2 from "../../assets/image/hero-2.jpg";
import hero3 from "../../assets/image/hero-3.jpg";
import hero4 from "../../assets/image/hero-4.jpg";
import { Link } from "react-router-dom";

// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";

import "./styles.css";

// import required modules
import { Pagination, Autoplay, Navigation } from "swiper";

const Carousel = () => {
  return (
    <div className="bg-blue-50">
      <>
        <Swiper
          spaceBetween={30}
          centeredSlides={true}
          autoplay={{
            delay: 5000,
            disableOnInteraction: true,
          }}
          pagination={{
            clickable: true,
          }}
          navigation={true}
          modules={[Autoplay, Navigation]}
          className="mySwiper"
        >
          <SwiperSlide>
            <div className=" bg-transparent bg-opacity-25 w-full lg:max-h-[600px] h-full px-3 flex justify-center items-center ">
              <div className="w-full p-6 h-full flex flex-col  lg:flex-row  justify-center items-center gap-6 lg:gap-0 ">
                <div className="lg:w-1/2  lg:px-12">
                  <p className=" mb-1 text-base uppercase">Trust us, because</p>
                  <h3 className="text-5xl text-black font-bold ">
                    We are{" "}
                    <span className="text-5xl text-[#13B5EA]">
                      leading pipe manufacturer{" "}
                    </span>{" "}
                    in Bangladesh
                  </h3>

                  <p className="text-base  mt-5 mb-6 lg:pr-12">
                    Sheikh Tube Mills Ltd. is a prominent pipe manufacturing
                    company in Bangladesh that has established itself as a
                    leader in the industry. Since its inception in 2017, the
                    company has consistently provided high-quality pipes to meet
                    the diverse needs of its customers.
                  </p>
                  <Link to="/about-us" className="mt-24">
                    <button className=" border-2 rounded  w-full lg:w-[250px] p-4 mt-4 bg-white hover:bg-base-color  font-medium hover:duration-200 hover:text-white ">
                      Learn More
                    </button>
                  </Link>
                </div>
                <div className="lg:w-1/2  hidden lg:block">
                  <img src={hero4} alt="section1" />
                </div>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="flex flex-col-reverse lg:max-h-[600px]  mt-6 lg:mt-0 lg:flex-row items-center justify-center w-full px-6 gap-4 lg:gap-0 lg:px-0 h-full ">
              <div className="lg:w-1/2  h-1/4 lg:h-full hidden lg:block">
                <img
                  src={hero3}
                  alt="section1"
                  className="h-[50%] lg:h-full object-cover "
                />
              </div>
              <div className="w-full h-full flex flex-col justify-center  lg:px-12  lg:w-1/2">
                <p className=" mb-1 text-base uppercase">Trust us, because</p>
                <h3 className=" text-5xl text-[#13B5EA] leading-10 text-start font-bold">
                  Advanced{" "}
                  <span className="text-5xl text-black">
                    {" "}
                    manufacturing <br /> techniques
                  </span>
                </h3>

                <p className="text-base text-start mt-5 mb-6 ">
                  Advanced manufacturing techniques and state-of-the-art
                  machinery to ensure the production of durable and reliable
                  pipes. Stringent quality checks are implemented at every stage
                  of the manufacturing process, from raw material sourcing to
                  the final product inspection. Sheikh Tube Mills Ltd. places
                  great emphasis on customer satisfaction. The company strives
                  to understand the unique requirements of its customers and
                  provides tailored solutions to meet their specific needs.
                </p>
                <Link to="/about-us">
                  <button className="  border-2 mb-4 rounded w-full lg:w-[250px] p-4 mt-4 bg-white hover:bg-base-color  font-medium hover:duration-200 hover:text-white">
                    Learn More
                  </button>
                </Link>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className=" bg-transparent bg-opacity-25 w-full  lg:min-h-[600px] lg:max-h-[600px] h-full lg:px-3 flex justify-center items-center ">
              <div className="w-full p-6 h-full flex flex-col  lg:flex-row  items-center gap-6 lg:gap-0">
                <div className="w-full lg:w-1/2 h-full  lg:px-12 ">
                  <p className=" mb-1 text-base uppercase">Trust us, because</p>
                  <h3 className=" text-5xl text-black  text-start font-bold">
                    We have{" "}
                    <span className="text-5xl text-[#13B5EA] leading-2">
                      skilled team
                    </span>
                  </h3>

                  <p className="ext-base text-start mt-5 mb-6 lg:mr-12">
                    Sheikh Tube Mills Ltd. is backed by a team of highly skilled
                    professionals who bring extensive industry knowledge and
                    expertise to the table. The employees are dedicated to
                    upholding the company's core values of integrity,
                    innovation, and continuous improvement.
                  </p>
                  <Link to="/about-us">
                    {" "}
                    <button className="border-2 rounded w-full lg:w-[250px] p-4 mt-4 bg-white hover:bg-base-color  font-medium hover:duration-200 hover:text-white">
                      {" "}
                      Learn More
                    </button>
                  </Link>
                </div>
                <div className="lg:w-1/2  hidden lg:block">
                  <img src={hero2} alt="section1" className="" />
                </div>
              </div>
            </div>
          </SwiperSlide>
        </Swiper>
      </>
    </div>
  );
};

export default Carousel;
