import React, { useEffect } from "react";
import { useState } from "react";
import ScrollToTop from "components/scroll-to-top";
import Banner from "assets/gif/career.gif";
import PageHeader from "components/page-header";
import { apiGet } from "utils/api";

const CareerDetails = () => {
  ScrollToTop();
  const [career, setcareer] = useState();

  useEffect(() => {
    const id = window.location.href.split("/")[4];
    if (!career) {
      apiGet(`career/list/${id}`)
        .then((data) => {
          console.log(data);
          setcareer(data.data.item);
        })
        .catch((err) => console.log(err));
    }
  });

  return (
    <>
      <div>
        <PageHeader banner={Banner} pageName="Job Details" />
      </div>

      <section className=" mt-10">
        <div className=" p-[10%]">
          <div className="flex flex-col items-center justify-center gap-4 p-4">
            <div className="col-md-12" id="post-single-block">
              <div className="post-container blog_text">
                <div className="text-center" style={{ padding: 10 }}></div>
                <hr />
                <table className="table table-bordered">
                  {career && (
                    <tbody className="border-2">
                      <tr className="bg-[#F4F6FA] ">
                        <th className="p-4 border-r-2">Feature</th>
                        <th className="p-4">Description</th>
                      </tr>
                      <tr className="border-t-2 ">
                        <td className="p-4 border-r-2">Job Title</td>
                        <td className="p-4"> {career.title}</td>
                      </tr>
                      <tr className="border-t-2 ">
                        <td className="p-4 border-r-2">Job Description</td>
                        <td className="p-4"> {career.jobDescription} </td>
                      </tr>
                      <tr className="border-t-2 ">
                        <td className="p-4 border-r-2">Requirement</td>
                        <td className="p-4">{career.jobRequirment}</td>
                      </tr>
                      <tr className="border-t-2 ">
                        <td className="p-4 border-r-2">
                          Required Skills &amp; Qualifications
                        </td>
                        <td className="p-4">{career.skills}</td>
                      </tr>
                      <tr className="border-t-2 ">
                        <td className="p-4 border-r-2">Vacancy No</td>
                        <td className="p-4">{career.vacancyNo}</td>
                      </tr>
                      <tr className="border-t-2 ">
                        <td className="p-4 border-r-2">Valid date</td>
                        <td className="p-4">{career.validDate}</td>
                      </tr>
                    </tbody>
                  )}
                </table>
              </div>
            </div>
            <div className="mt-4">
              <div className="">
                <button type="submit" className="theme-btn btn-style-one">
                  <span className="txt">Apply Now</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default CareerDetails;
