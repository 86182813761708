import React from "react";
import { Link } from "react-router-dom";

const PageHeader = ({ pageName, banner }) => {
  return (
    <div
      className="w-full  h-[200px] flex flex-col justify-center items-center relative"
      style={{
        backgroundImage: `url( ${banner})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
      }}
    >
      <p className=" text-3xl font-bold text-white uppercase">{pageName}</p>

      <div className=" absolute h-14  -bottom-7 p-2 bg-black text-white w-72 flex justify-center items-center">
        <div className=" absolute w-4 h-4 bg-white rounded-full -left-2"></div>
        <div className=" font-semibold text-sm uppercase">
          <p>
            <Link to="/">
              <span className=" hover:text-base-color hover:duration-200">
                Home
              </span>
            </Link>
            <span className=" ml-2 mr-2 text-base">></span>
            {pageName}
          </p>
        </div>
        <div className=" absolute w-4 h-4 bg-white rounded-full -right-2"></div>
      </div>
    </div>
  );
};

export default PageHeader;
