import React from "react";
import PageHeader from "../../components/page-header";
import banner from "assets/banner/banner.png";
import { useState } from "react";
import { apiPost } from "../../utils/api";

const ContactUs = () => {
  const [inputs, setInputs] = useState({
    name: "",
    email: "",
    phone: "",
    subject: "",
    comment: "",
  });

  const handleChange = (e) => {
    setInputs((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const sendRequest = async () => {
    const res = await apiPost("contact/addContactInfo", {
      name: inputs.name,
      email: inputs.email,
      phone: inputs.phone,
      subject: inputs.subject,
      comment: inputs.comment,
    }).catch((err) => console.log(err));

    const data = await res.data;
    return data;
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(inputs);
    sendRequest().then(() =>
      setInputs({
        name: "",
        email: "",
        phone: "",
        subject: "",
        comment: "",
      })
    );
  };
  return (
    <div>
      <div>
        <PageHeader pageName={"Contact us"} banner={banner} />
      </div>
      <div className="w-full flex flex-col items-center justify-center p-[5%]  lg:p-[15%]">
        <div className=" w-full">
          <iframe
            width="100%"
            height="600"
            frameborder="0"
            scrolling="no"
            marginheight="0"
            marginwidth="0"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1570.8773005255648!2d91.84364845855787!3d22.308288434004545!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x30ad21ee3306a5df%3A0x3a9b2c10d3fcb065!2sSHEIKH%20TUBE%20MILLS%20LTD!5e0!3m2!1sen!2sus!4v1649184669084!5m2!1sen!2sus"
          ></iframe>
        </div>
        <div className="w-full justify-between flex flex-col lg:flex-row gap-6 lg:gap-0 mt-10">
          <div>
            <p className=" text-5xl text-base-color  text-start font-bold">
              Contact us
            </p>
            <p className=" text-base font-medium">
              Our door is always open for a good cup of coffee. <br /> Lets meet
              and build the industry smarter.
            </p>
            <div className=" mt-6">
              <div className=" flex items-center gap-4">
                <i class="fas fa-home text-lg"></i>
                <p className=" text-lg text-base-color">
                  Karnafuly thana road ,ward#3 charlakkah, Chittagong
                </p>
              </div>
              <div className=" flex items-center gap-4 mt-2">
                <i class="fas fa-at text-lg"></i>
                <p className=" text-lg text-base-color">
                  <a href="mailto:nfo@sheikhtubemills.com">
                    info@sheikhtubemills.com
                  </a>
                </p>
              </div>
              <div className=" flex items-center gap-4 mt-2">
                <i class="fas fa-phone text-lg"></i>
                <p className=" text-lg text-base-color">+8801670-850303</p>
              </div>
            </div>
          </div>
          <div className="lg:w-1/2">
            <div className=" contact-form">
              <form onSubmit={handleSubmit} id="contact-form">
                <div className="contact-form-top">
                  <input
                    type="text"
                    placeholder="Your name..."
                    name="name"
                    value={inputs.name}
                    onChange={handleChange}
                    className=" outline-none appearance-none"
                    required
                  />
                  <input
                    type="email"
                    placeholder="Your email"
                    name="email"
                    value={inputs.email}
                    onChange={handleChange}
                    id="email"
                    className="outline-none appearance-none"
                    required
                  />
                  <input
                    type="text"
                    placeholder="Your phone number"
                    name="phone"
                    value={inputs.phone}
                    onChange={handleChange}
                    id="phone"
                    className="outline-none appearance-none"
                    required
                  />
                  <input
                    type="text"
                    placeholder="Company Name"
                    name="subject"
                    value={inputs.subject}
                    onChange={handleChange}
                    id="subject"
                    className="outline-none appearance-none"
                    required
                  />
                </div>
                <textarea
                  name="comment"
                  value={inputs.comment}
                  onChange={handleChange}
                  id="message"
                  cols={3}
                  rows={3}
                  placeholder="Comment..."
                  className="outline-none appearance-none"
                  required
                  defaultValue={""}
                />
                <div className="mt-5">
                  <button type="submit" className="theme-btn btn-style-one ">
                    <span class="txt">Submit now</span>
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactUs;
