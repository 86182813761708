import React from "react";
import PageHeader from "../../components/page-header";
import banner from "assets/banner/banner.png";
import frame1 from "../../assets/image/hero-4.jpg";
import frame from "../../assets/image/hero-2.jpg";
import Quote from "../../components/quote";

const About = () => {
  return (
    <div>
      <PageHeader pageName={"About us"} banner={banner} />
      <div className="mt-12 lg:mt-0">
        <div className="  flex flex-col lg:flex-row gap-6 lg:gap-0 p-[10%]">
          <div className=" lg:w-1/2">
            <div>
              <p className="text-2xl lg:text-5xl text-black text-start font-bold">
                <span className=" text-base-color"> About </span>
                Sheikh Tube Mills LTD.
              </p>
            </div>
            <div className=" mt-10 lg:pr-14">
              <p>
                The success of{" "}
                <span className=" uppercase">Sheikh Tube Mills Ltd</span> can be
                attributed to its commitment to delivering superior products and
                maintaining stringent quality control measures. The company
                utilizes advanced manufacturing techniques and state-of-the-art
                machinery to ensure the production of durable and reliable
                pipes. Stringent quality checks are implemented at every stage
                of the manufacturing process, from raw material sourcing to the
                final product inspection. Sheikh Tube Mills Ltd. places great
                emphasis on customer satisfaction. The company strives to
                understand the unique requirements of its customers and provides
                tailored solutions to meet their specific needs. <br /> <br />{" "}
                By maintaining strong relationships with clients and
                consistently delivering on its promises, the company has earned
                a reputation for reliability and trustworthiness in the market.
                In addition to its focus on quality and customer satisfaction,
                Sheikh Tube Mills Ltd. also prioritizes environmental
                sustainability. The company adopts eco-friendly practices and
                adheres to stringent environmental regulations during its
                manufacturing processes. It promotes responsible use of
                resources and invests in technologies that minimize the
                environmental impact of its operations.
              </p>

              <div>
                <ul className=" mt-5 text-lg font-semibold text-base-color">
                  <li>
                    {" "}
                    <i class="fas fa-globe mr-2"></i> Vast Local supplier
                  </li>
                  <li>
                    {" "}
                    <i class="fas fa-globe mr-2"></i> Comprehensive Support for
                    Your Business
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className=" lg:w-1/2">
            <div className="video-box">
              <figure className="image">
                <img src={frame1} alt />
              </figure>
              <a
                href="https://www.youtube.com/watch?v=CSjyVJGLfO4"
                className="lightbox-image overlay-box"
              >
                <span className="flaticon-arrow ">
                  <i class="fas fa-play"></i>{" "}
                </span>
              </a>
            </div>
          </div>
        </div>
        <div className="flex flex-col lg:flex-row">
          <div className="lg:w-1/2">
            <img src={frame} alt="" className=" object-cover w-full h-full" />
          </div>
          <div className="lg:w-1/2 bg-[#F9F9F9]">
            <div className=" p-[10%]">
              <p className=" text-2xl lg:text-5xl text-base-color  text-start font-bold">
                <span className=" border-base-color">Why </span>{" "}
                <span className="text-black"> choose us</span>
              </p>
              <p className=" mt-10 ">
                Sheikh Tube Mills Ltd. is backed by a team of highly skilled
                professionals who bring extensive industry knowledge and
                expertise to the table. The employees are dedicated to upholding
                the company's core values of integrity, innovation, and
                continuous improvement. Through ongoing training and development
                programs, the company ensures that its workforce remains
                up-to-date with the latest industry trends and technologies. As
                a testament to its success, Sheikh Tube Mills Ltd. has earned
                various certifications and accolades over the years. The
                company's commitment to quality and excellence has been
                recognized by industry bodies, further establishing its position
                as a leading pipe manufacturer in Bangladesh. <br />
                <br />
                Looking towards the future, Sheikh Tube Mills Ltd. aims to
                further expand its product offerings and market reach. The
                company continually invests in research and development to
                introduce innovative pipe solutions that cater to evolving
                industry demands. With a strong focus on customer satisfaction,
                quality, and sustainability, Sheikh Tube Mills Ltd. is poised to
                continue its growth trajectory and maintain its position as a
                trusted name in the pipe manufacturing industry in Bangladesh.
              </p>
              <div className=" flex justify-center items-center gap-10">
                <div className="flex gap-5 flex-col mt-10">
                  <div className="flex gap-4 items-center">
                    <i class="fas fa-chart-pie text-4xl text-base-color"></i>
                    <p className="text-xl font-semibold">Advanced Technology</p>
                  </div>
                  <div className="flex gap-4 items-center">
                    <i class="fas fa-funnel-dollar text-4xl text-base-color"></i>
                    <p className="text-xl font-semibold">Corporate Deal</p>
                  </div>
                </div>

                <div className="flex gap-5 flex-col mt-10">
                  <div className="flex gap-4 items-center">
                    <i class="fas fa-tachometer-alt text-4xl text-base-color"></i>
                    <p className="text-xl font-semibold">Skilled Worker</p>
                  </div>
                  <div className="flex gap-4 items-center">
                    <i class="fas fa-globe-europe text-4xl text-base-color"></i>
                    <p className="text-xl font-semibold">
                      Our Workflow & Process
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Quote />
      </div>
    </div>
  );
};

export default About;
