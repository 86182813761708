import React, { useEffect, useState } from "react";
import PageHeader from "../../components/page-header";
import banner from "assets/banner/banner.png";
import { apiGet } from "../../utils/api";
import { staticURL } from "../../utils/config";
import EmptyBox from "../../components/empty-window/empty-box";

const Products = () => {
  const [inputs, setInputs] = useState();

  console.log(staticURL);

  const fetchData = async () => {
    const res = await apiGet("product").catch((err) => console.log(err));

    const data = await res.data;
    return data;
  };
  //   console.log(inputs);
  useEffect(() => {
    if (!inputs) {
      fetchData().then((data) => setInputs(data.item));
    }
  });

  console.log(inputs);
  return (
    <div>
      <div>
        <PageHeader banner={banner} pageName="Products" />
      </div>
      <div className=" mt-12 flex flex-wrap gap-4 p-8 justify-center items-center">
        {inputs?.length > 0 ? (
          inputs.map((item, idx) => {
            return (
              <div className="relative w-[400px] h-[500px] overflow-hidden rounded-lg shadow-lg cursor-pointer m-4 dark:bg-gray-600 duration-300 ease-in-out transition-transform transform hover:-translate-y-2">
                <img
                  className="object-cover w-full h-80"
                  src={`https://turbo.sheikhtubemills.com/static/${item.image}`}
                  alt={"ïmg"}
                />

                <div className="items-center text-justify m-3 ">
                  <p className="text-lg font-bold text-gray-700 dark:text-gray-200">
                    {item.description}
                  </p>
                </div>
              </div>
            );
          })
        ) : (
          <EmptyBox label={"No product found"} />
        )}
      </div>
    </div>
  );
};

export default Products;
