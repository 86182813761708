import React, { useRef, useState } from "react";
import logo from "../../assets/image/logo.png";
import { Link } from "react-router-dom";

const Header = () => {
  const [openBar, setOpenbar] = useState(false);
  const [hover, setHover] = useState(false);
  const [active, setActive] = useState(false);

  const mouseRef = useRef(null);

  const handleActive = () => {
    setActive(!active);
  };

  const handleBar = () => {
    setOpenbar(!openBar);
  };
  return (
    <div className="  relative">
      <div className=" flex flex-col lg:flex-row justify-around  items-center gap-4 lg:gap-0">
        <div className=" w-54 lg:w-96">
          <img src={logo} alt="" />
        </div>
        <div className=" flex flex-col lg:flex-row items-center gap-8">
          <div className=" flex gap-4 flex-col lg:flex-row items-center">
            <i className="fas fa-map-marker-alt text-base-color text-2xl"></i>
            <p className=" text-center lg:text-start">
              Factory: Karnafuly Thana Road <br /> Ward No:3 Charlakkah,
              Chittagong.
            </p>
          </div>
          <div className=" flex flex-col lg:flex-row gap-4 items-center">
            <i className="fas fa-phone-alt  text-base-color text-2xl"></i>
            <p className="text-center lg:text-start">
              +8801843507243 <br /> info@sheikhtubemills.com
            </p>
          </div>
          <div className=" flex flex-col lg:flex-row gap-4 items-center">
            <i className="fas fa-clock text-base-color text-2xl"></i>
            <p className=" text-center lg:text-start">
              10:00am To 6:00pm <br />
              Friday Closed
            </p>
          </div>
        </div>
      </div>
      <div className=" px-[10%] flex mt-6 ">
        <div className=" h-full  hidden lg:block bg-white ">
          <div className="   rotate-180  border-t-transparent  border-l-[100px] border-l-base-color  border-b-[95px] border-b-transparent  "></div>
        </div>
        {/* main navbar */}
        <div className="h-22 w-full bg-base-color flex flex-col lg:flex-row justify-between">
          <div className="w-full flex justify-between items-center p-4">
            <div className=" hidden lg:block">
              <div className=" flex  gap-5 ">
                <div>
                  <Link to="/">
                    <p className=" text-lg text-white hover:text-black font-medium cursor-pointer">
                      HOME
                    </p>
                  </Link>
                </div>
                <div>
                  <Link to="/products">
                    <p className=" text-lg text-white hover:text-black font-medium cursor-pointer">
                      PRODUCTS
                    </p>
                  </Link>
                </div>
                <div
                  className=" relative flex gap-2 items-center z-[999] text-white hover:text-black"
                  ref={mouseRef}
                  onMouseEnter={() => {
                    setHover(true);
                    setActive(true);
                  }}
                >
                  <p className=" text-lg   font-medium cursor-pointer">ORDER</p>
                  <div
                    onClick={() => {
                      setActive(false);
                    }}
                  >
                    <i class="fas fa-chevron-down text-xs"></i>
                  </div>
                  {hover && active && (
                    <div
                      className=" absolute top-[60px] bottom-0 bg-white  shadow-lg  w-[200px] h-[120px]"
                      onMouseLeave={() => {
                        setHover(false);
                        setActive(false);
                      }}
                    >
                      <div
                        className=" text-black hover:text-white hover:bg-base-color py-4 px-3 "
                        onClick={() => setActive(false)}
                      >
                        <Link to={"/pipe"}>
                          <p className=" text-lg    font-medium cursor-pointer uppercase">
                            Pipe
                          </p>
                        </Link>
                      </div>
                      <div
                        className=" hover:bg-base-color text-black hover:text-white  py-4 px-3 "
                        onClick={() => setActive(false)}
                      >
                        <Link to="/scrap">
                          <p className=" text-lg   font-medium cursor-pointer uppercase">
                            Scrap
                          </p>
                        </Link>
                      </div>
                    </div>
                  )}
                </div>

                <div>
                  <Link to="/news">
                    <p className=" text-lg text-white hover:text-black font-medium cursor-pointer">
                      NEWS
                    </p>
                  </Link>
                </div>
                <div>
                  <Link to={"/about-us"}>
                    {" "}
                    <p className=" text-lg text-white hover:text-black font-medium cursor-pointer">
                      ABOUT US
                    </p>
                  </Link>
                </div>
                <div>
                  <Link to={"/contact-us"}>
                    <p className=" text-lg text-white hover:text-black font-medium cursor-pointer">
                      CONTACT US
                    </p>
                  </Link>
                </div>
              </div>
            </div>

            <div>
              {/* <i className="fas fa-search text-lg text-white"></i> */}
            </div>
            <div className=" block lg:hidden">
              <div onClick={handleBar}>
                <i className="fas fa-bars text-white"></i>
              </div>
            </div>
          </div>
          {/* mobile view */}
          <div className=" block lg:hidden">
            {openBar && (
              <div className=" px-4 py-3 flex flex-col ">
                <div className=" border p-2" onClick={handleBar}>
                  <Link to={"/"}>
                    <p className=" text-lg text-white hover:text-black font-semibold cursor-pointer">
                      HOME
                    </p>
                  </Link>
                </div>
                <div className=" border p-2" onClick={handleBar}>
                  <Link to={"/products"}>
                    <p className=" text-lg text-white hover:text-black font-semibold cursor-pointer">
                      PRODUCTS
                    </p>
                  </Link>
                </div>
                <div className=" border p-2 ">
                  <div className="flex flex-row gap-2" onClick={handleActive}>
                    <p className=" text-lg text-white hover:text-black font-semibold cursor-pointer">
                      ORDER
                    </p>
                    <div>
                      <i class="fas fa-chevron-down text-xs text-white"></i>
                    </div>
                  </div>
                  {active && (
                    <div className=" px-2 mt-4 " onClick={handleBar}>
                      <Link to={"/pipe"}>
                        <p className=" text-lg text-white  font-semibold cursor-pointer uppercase">
                          Pipe
                        </p>
                      </Link>

                      <Link to="/scrap">
                        <p className=" text-lg text-white  font-semibold cursor-pointer uppercase mt-2">
                          Scrap
                        </p>
                      </Link>
                    </div>
                  )}
                </div>
                <div className=" border p-2" onClick={handleBar}>
                  <Link to="/news">
                    <p className=" text-lg text-white hover:text-black font-semibold cursor-pointer">
                      NEWS
                    </p>
                  </Link>
                </div>
                <div className=" border p-2" onClick={handleBar}>
                  <Link to={"/about-us"}>
                    <p className=" text-lg text-white hover:text-black font-semibold cursor-pointer">
                      ABOUT US
                    </p>
                  </Link>
                </div>
                <div className=" border p-2" onClick={handleBar}>
                  <Link to="/contact-us">
                    <p className=" text-lg text-white hover:text-black font-semibold cursor-pointer">
                      CONTACT US
                    </p>
                  </Link>
                </div>
              </div>
            )}
          </div>
        </div>

        <div className=" h-full  hidden lg:block bg-white ">
          <div className="  border-t-[95px]  border-t-transparent  border-l-[100px] border-l-base-color  border-b-transparent  "></div>
        </div>
      </div>
    </div>
  );
};

export default Header;
