import React, { useEffect, useState } from "react";
import logo from "../../assets/image/logo.png";
import PrimaryBtn from "../button/primary-btn";
import icon from "../../assets/icons/earth.png";
import location from "../../assets/icons/location.png";
import tel from "../../assets/icons/telephone.png";
import mail from "../../assets/icons/mail.png";
import { Link, useNavigate } from "react-router-dom";
import { apiGet } from "utils/api";
import { getDateFormatAsDateMonthYear } from "utils/dates";
import ScrollToTop from "components/scroll-to-top";

const Footer = () => {
  const navigate = useNavigate();
  const [news, setNews] = useState("");

  const getNews = async () => {
    const res = await apiGet("news");
    const data = await res.data;

    return data;
  };

  useEffect(() => {
    if (!news) {
      getNews().then((data) => setNews(data.news));
    }
  }, []);
  console.log(news);

  const handleNavigate = (id) => {
    navigate(`/single-news/${id}`);
    window.location.reload(true);
    ScrollToTop();
  };

  const date = new Date();
  const year = date.getFullYear();
  return (
    <div className="bg-[#1C1C1C] text-white">
      <div className="    px-[5%] lg:px-[10%]">
        <div className=" border-x  border-gray-600 ">
          <div className=" flex flex-col lg:flex-row lg:justify-evenly lg:items-center  w-full">
            <div className="  lg:w-1/3 px-6 my-4">
              <div className=" w-auto mt-10">
                <div className=" bg-white lg:w-[200px] ">
                  {/* <img src={logo} alt="" />{" "}
                   */}
                  {/* <p className="p-4 text-black text-center font-medium text-2xl">
                    About us
                  </p> */}
                </div>
                <p className=" text-sm w-full lg:w-[70%] my-5 text-justify">
                  Looking towards the future,{" "}
                  <span className=" uppercase">Sheikh Tube Mills Ltd. </span>
                  Aims to further expand its product offerings and market reach.
                </p>
                <Link to="/about-us">
                  <div className=" mt-2">
                    <PrimaryBtn btnName={"Read More"} />
                  </div>
                </Link>
              </div>
            </div>
            <div className="lg:w-1/3 h-full border-t lg:border-t-0 mt-4 lg:mt-0 lg:border-l border-gray-600  px-6">
              <div className="">
                <p className=" font-medium text-2xl  mt-9">Usefull Links</p>
                <div className=" w-[40px] lg:border-b-2  mt-1"></div>
                <div className=" mt-6 flex gap-6">
                  <div className=" flex flex-col gap-4">
                    <Link to="/faq">
                      <div className=" cursor-pointer text-sm hover:text-base-color">
                        > FAQ
                      </div>
                    </Link>
                    <Link to={"/delivery-tracking"}>
                      <div className=" cursor-pointer text-sm hover:text-base-color">
                        > Delivery Tracking
                      </div>
                    </Link>
                    <Link to="/truck-rent">
                      {" "}
                      <div className=" cursor-pointer text-sm hover:text-base-color">
                        > Truck Rent
                      </div>{" "}
                    </Link>
                    <Link to="/become-agent">
                      <div className=" cursor-pointer text-sm hover:text-base-color">
                        > Become Agent
                      </div>
                    </Link>
                    {/* <div className=" cursor-pointer text-sm hover:text-base-color">
                      > Pdf Download
                    </div> */}
                  </div>
                  <div>
                    <div className=" flex flex-col gap-4">
                      <Link to={"/career"}>
                        <div className=" cursor-pointer text-sm hover:text-base-color">
                          > Career
                        </div>
                      </Link>
                      <Link to={"/testimonials"}>
                        <div className=" cursor-pointer text-sm hover:text-base-color">
                          > Testimonials
                        </div>
                      </Link>
                      <Link to={"/free-consultation"}>
                        <div className=" cursor-pointer text-sm hover:text-base-color">
                          > Free Consultation
                        </div>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="lg:w-1/3  border-t lg:border-t-0 mt-4 lg:mt-0 lg:border-l border-gray-600  px-6">
              <p className=" font-medium text-2xl  mt-9">Latest News</p>
              <div className=" w-[40px] border-b-2  mt-1"></div>
              {news &&
                news
                  ?.slice(0, 2)
                  .map((item, idx) => {
                    return (
                      <div
                        className=" mt-5 flex gap-5 items-center cursor-pointer"
                        onClick={() => {
                          handleNavigate(item._id);
                        }}
                      >
                        <div className=" w-10 h-10">
                          <img src={icon} alt="" />
                        </div>
                        <div>
                          <p className=" text-base-color text-sm ">
                            {getDateFormatAsDateMonthYear(item.date)}
                          </p>
                          <p className=" font-medium mt-1">{item.title}</p>
                        </div>
                      </div>
                    );
                  })
                  .reverse()}
            </div>
          </div>
          <div className="flex flex-col lg:flex-row gap-5 lg:gap-0 justify-around  border-t border-gray-600 mt-6 py-5">
            <div className=" lg:w-1/3 flex gap-4 items-center  px-6">
              <div className=" w-10 h-10">
                <img src={location} alt="" />
              </div>
              <div>
                <p className=" font-semibold ">Address</p>
                <span className=" text-sm">
                  Karnafuly Thana Road Ward No:3 Charlakkah, Chittagong
                </span>
              </div>
            </div>
            <div className=" lg:w-1/3 flex gap-4 items-center lg:border-l lg:border-gray-600 px-6 ">
              <div className=" w-8 h-10">
                <img src={tel} alt="" />
              </div>
              <div>
                <p className=" font-semibold ">Call Us On</p>
                <span className=" text-sm">
                  Front Desk: <a href="tel:+8801843507243">+880-184-3507 243</a>
                </span>
              </div>
            </div>
            <div className="lg:w-1/3 flex gap-4 items-center lg:border-l border-gray-600 px-6">
              <div className=" w-8 h-10">
                <img src={mail} alt="" />
              </div>
              <div>
                <p className=" font-semibold ">Email at</p>
                <span className=" text-sm">
                  <a href="mailto:info@Sheikhtubemills.Com">
                    info@sheikhtubemills.com
                  </a>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className=" border-t border-gray-600 py-5 px-[5%] lg:px-[10%]">
        <div className=" flex flex-col lg:flex-row justify-center lg:justify-between  items-center lg:items-start">
          <div className=" w-1/2 text-center lg:text-start">
            <p className=" text-xs">
              Copyrights © {year} All Rights Reserved. Powered by Sheikh Tube
              Mills LTD.
            </p>
          </div>
          <div className=" flex flex-col lg:flex-row gap-2 lg:gap-4 mt-4 lg:mt-0 text-sm text-center lg:text-start">
            <p className="hover:text-base-color cursor-pointer hover:duration-200">
              Privacy Policy
            </p>
            <p className="hover:text-base-color cursor-pointer hover:duration-200">
              Terms & Condition
            </p>
          </div>
        </div>
        <div className=" flex  justify-center lg:justify-end mt-5 text-sm">
          <p className=" text-gray-400">
            Developed by
            <a
              href="https://www.dellly.com/"
              className=" hover:text-base-color hover:duration-200 text-gray-200 ml-1"
              target="_blank"
              rel="noreferrer"
            >
              Dellly
            </a>
          </p>
        </div>
      </div>
    </div>
  );
};

export default Footer;
