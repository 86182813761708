import React from "react";
import Carousel from "../../components/carousel";
import Newsletter from "../../components/newsletter";
import Quote from "../../components/quote";
import HeroSection from "./HeroSection";
import { Helmet } from "react-helmet";
import logo from "assets/image/logo.png";

const Home = () => {
  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title> Sheikh Tube Mills Ltd</title>
        <link rel="canonical" href="https://sheikhtubemills.com" />
        <meta
          name="description"
          content="Sheikh Tube Mills LTD. is one of the leading pipe manufacturers in Bangladesh. From 2017 to this date we have been in the whole country with pride and confidence. We have a talented and multitasking team to design, develop and manufacture pipes to serve our customers with the best quality materials. It has a wide range of construction, manufacturer, and industries to households where STM(Sheikh Tube Mills LTD.) pipes have all the solutions"
        />
        <meta property="og:image" content={logo} />
      </Helmet>

      <Carousel />
      <HeroSection />
      <Quote />
      <Newsletter />
    </div>
  );
};

export default Home;
