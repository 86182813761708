import React from "react";
import PageHeader from "../../components/page-header";
import banner from "assets/banner/banner.png";
import { useState } from "react";
import { apiGet } from "../../utils/api";
import { useEffect } from "react";
import { ApiURL, staticURL } from "../../utils/config";
import { getReadableStringDate } from "../../utils/dates";
import DOMPurify from "dompurify";
import { Link } from "react-router-dom";
import EmptyBox from "components/empty-window/empty-box";

const News = () => {
  const [data, setData] = useState([]);

  const getAllNews = async () => {
    await apiGet("news")
      .then((res) => {
        return res;
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    if (!data) {
      getAllNews().then((data) => setData(data.news));
    }
  }, []);
  return (
    <div>
      <div>
        <PageHeader pageName={"News"} banner={banner} />
      </div>
      <div className=" flex flex-col lg:flex-row flex-wrap justify-center p-[10%] gap-5">
        {data?.length > 0 ? (
          data.map((item, idx) => {
            return (
              <div className="news-block lg:w-[23%] " key={idx}>
                <div
                  className="inner-box wow fadeInLeft"
                  data-wow-delay="600ms"
                  data-wow-duration="1500ms"
                >
                  <div className="image">
                    <div
                      href="blog-single.html"
                      style={
                        {
                          // width: "150px",
                          // height: "150px",
                        }
                      }
                    >
                      <img
                        src={`${staticURL}/static/${item.image}`}
                        alt={item.title}
                        style={
                          {
                            // width: "200px",
                            // height: "200px",
                          }
                        }
                      />
                    </div>
                    <ul className="category">
                      <li>
                        <a href="blog-single.html">
                          {item.category === null ? "industry" : item.category}
                        </a>
                      </li>
                    </ul>
                  </div>
                  <div className="lower-content">
                    <div className="author">{item.author}</div>
                    <Link to={`/single-news/${item._id}`}>
                      <h5>{item.title}</h5>
                    </Link>
                    <p
                      className="text "
                      style={{}}
                      dangerouslySetInnerHTML={{
                        __html: DOMPurify.sanitize(
                          item.description
                            .replace(/(?:<h2>|<h1>|<h3>|<h4>|<h5>)/gi, "<p>")
                            .substring(0, 50) + "..."
                        ),
                      }}
                    ></p>
                    <ul className="post-date">
                      <li>{getReadableStringDate(item.date)}</li>
                    </ul>
                  </div>
                </div>
              </div>
            );
          })
        ) : (
          <EmptyBox label={"No news found"} />
        )}
      </div>
    </div>
  );
};

export default News;
