import PageHeader from "components/page-header";
import ScrollToTop from "components/scroll-to-top";
import React, { useState } from "react";
import banner from "assets/banner/banner.png";
import { apiPost } from "utils/api";
import { useToast } from "../../components/toast";

const Agent = () => {
  ScrollToTop();
  const { add } = useToast();
  const [inputs, setinputs] = useState({
    name: "",
    email: "",
    phone: "",
    companyName: "",
    location: "",
    message: "",
  });

  const handleChange = (e) => {
    setinputs((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const sendRequest = async () => {
    await apiPost("agent/addAgent", {
      name: inputs.name,
      email: inputs.email,
      phone: inputs.phone,
      companyName: inputs.companyName,
      location: inputs.location,
      message: inputs.message,
    }).catch((err) => {
      add({
        type: "warning",
        message: err.message,
      });
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (
      inputs.name === "" ||
      inputs.email === "" ||
      inputs.phone === "" ||
      inputs.companyName === "" ||
      inputs.location === "" ||
      inputs.message === ""
    ) {
      add({
        type: "warning",
        message: "Please fill all the fields",
      });
      return;
    }
    sendRequest()
      .then(() => {
        add({
          type: "success",
          message:
            "Your information has been recorded!! We will contact you shortly.",
        });
      })
      .then(() =>
        setinputs({
          name: "",
          email: "",
          phone: "",
          companyName: "",
          location: "",
          message: "",
        })
      );
  };
  return (
    <div>
      <div>
        <PageHeader banner={banner} pageName="Become an Agent" />
      </div>
      <div className="mt-10">
        <section className="register-section">
          <div className="auto-container">
            <div className="py-[5%] px-[10%] lg:px-[20%]">
              <div className=" w-full text-center">
                <p className=" text-2xl mb-4 font-bold text-base-color  uppercase">
                  Be our agent. Lets build great industry
                </p>
              </div>
              {/*Form Column*/}
              {/*  <div class="form-column column col-lg-8 col-md-8 col-sm-12 "> */}
              <div className="">
                <div className="welcome_text">
                  <form
                    onSubmit={handleSubmit}
                    method="post"
                    className="flex flex-col gap-2"
                  >
                    <div className="border border-base-color w-full p-3">
                      <input
                        type="text"
                        name="name"
                        value={inputs.name}
                        onChange={handleChange}
                        className=" appearance-none outline-none w-full text-black"
                        placeholder="Name"
                        required
                      />
                    </div>
                    <div className="border border-base-color w-full p-3">
                      <input
                        type="email"
                        name="email"
                        value={inputs.email}
                        onChange={handleChange}
                        className=" appearance-none outline-none w-full text-black"
                        placeholder="Email"
                      />
                    </div>
                    <div className="border border-base-color w-full p-3">
                      <input
                        type="text"
                        name="phone"
                        value={inputs.phone}
                        onChange={handleChange}
                        className=" appearance-none outline-none w-full text-black"
                        placeholder="Phone Number"
                      />
                    </div>
                    <div className="border border-base-color w-full p-3">
                      <input
                        type="text"
                        name="companyName"
                        value={inputs.companyName}
                        onChange={handleChange}
                        className=" appearance-none outline-none w-full text-black"
                        placeholder="Company Name "
                        required
                      />
                    </div>
                    <div className="border border-base-color w-full p-3">
                      <input
                        type="text"
                        name="location"
                        value={inputs.location}
                        onChange={handleChange}
                        className=" appearance-none outline-none w-full text-black"
                        placeholder="Location"
                        required
                      />
                    </div>
                    <div className="border border-base-color w-full p-3">
                      <textarea
                        name="message"
                        value={inputs.message}
                        onChange={handleChange}
                        className="appearance-none outline-none w-full text-black"
                        placeholder="Quote for agent"
                        rows={4}
                        cols={140}
                      />
                    </div>
                    <div className="form-group">
                      <button
                        type="submit"
                        className="p-4 text-white  font-semibold bg-base-color hover:bg-black hover:duration-300"
                        style={{ backgroundColor: "#13b5ea" }}
                      >
                        Submit
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default Agent;
