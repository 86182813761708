import PageHeader from "components/page-header";
import React, { useEffect, useState } from "react";
import { apiGet } from "utils/api";
import { staticURL } from "utils/config";
import banner from "assets/banner/banner.png";
import ScrollToTop from "components/scroll-to-top";
const Testimonial = () => {
  const [items, setItems] = useState("");

  const getItem = async () => {
    try {
      const url = "testimonial";
      const res = await apiGet(url);

      const data = await res.data;
      return data;
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    if (!items) {
      getItem().then((data) => setItems(data.items));
    }
  });

  ScrollToTop();

  return (
    <div>
      <div>
        <PageHeader banner={banner} pageName={"Testimonial"} />
      </div>
      <div className=" mt-10">
        <div className=" flex flex-wrap gap-2 lg:gap-10 items-center justify-center w-full p-[10%]">
          {" "}
          {items &&
            items.map((item, idx) => {
              return (
                <div className="testimonial-block-two w-full lg:w-[40%]">
                  <div className="inner-box">
                    <div className="text">{item.testimonial}</div>
                    <div className="author-box">
                      <div className="inner">
                        <div className="image">
                          <img src={`${staticURL}/static/${item.image}`} alt />
                        </div>
                        <h5>{item.name}</h5>
                        <div className="designation">{item.designation}</div>
                        <div className="designation">{item.company}</div>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
        </div>
      </div>
    </div>
  );
};

export default Testimonial;
