import axios from "axios";
import { ApiURL } from "../config";

const baseUrl = ApiURL;
console.log(baseUrl);

const config = {
  headers: {
    "Content-Type": "application/json",
  },
};
export const apiGet = async (url, ...rest) => {
  const requestURL = `${baseUrl}/${url}`;
  const response = axios.get(requestURL, config, ...rest);
  return response;
};

export const apiPost = async (url, inputs, ...rest) => {
  const requestURL = `${baseUrl}/${url}`;
  const response = axios.post(requestURL, inputs, config, ...rest);
  return response;
};

export const apiDelete = async (url, inputs, ...rest) => {
  const requestURL = `${baseUrl}/${url}`;
  const response = axios.delete(requestURL, inputs, config, ...rest);
  return response;
};
