import React from "react";
import { useState } from "react";
import banner from "assets/banner/banner.png";
import { apiPost } from "utils/api";
import PageHeader from "components/page-header";
import ScrollToTop from "components/scroll-to-top";
import { useToast } from "../../components/toast";

const TruckRent = () => {
  const { add } = useToast();
  ScrollToTop();
  const [inputs, setinputs] = useState({
    name: "",
    email: "",
    phone: "",
    companyAddress: "",
    productQuantity: "",
    destination: "",
    message: "",
  });

  const handleChange = (e) => {
    setinputs((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const sendRequest = async () => {
    await apiPost("truckRent/addinfo", {
      name: inputs.name,
      email: inputs.email,
      phone: inputs.phone,
      companyAddress: inputs.companyAddress,
      productQuantity: inputs.productQuantity,
      destination: inputs.destination,
      message: inputs.message,
    }).catch((err) => {
      add({
        type: "warning",
        message: err.message,
      });
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    sendRequest()
      .then(() => {
        add({
          type: "success",
          message:
            "Your Agent Information Has Been Recorded!! We will Contact you Shortly.",
        });
      })
      .then(() =>
        setinputs({
          name: "",
          email: "",
          phone: "",
          companyAddress: "",
          productQuantity: "",
          destination: "",
          message: "",
        })
      );
  };
  return (
    <div>
      <div>
        <div>
          <PageHeader banner={banner} pageName="Rent a truck" />
        </div>
        <div className="mt-10">
          <section className="register-section">
            <div className="auto-container">
              <div className="py-[5%] px-[10%] lg:px-[20%]">
                <div className=" w-full text-center">
                  <p className=" text-2xl mb-4 font-bold text-base-color  uppercase">
                    We deliver your needs. Let us know your need. <br /> our
                    team is ready to serve you.
                  </p>
                </div>
                {/*Form Column*/}
                {/*  <div class="form-column column col-lg-8 col-md-8 col-sm-12 "> */}
                <div className="">
                  <div className="welcome_text">
                    <form
                      onSubmit={handleSubmit}
                      method="post"
                      className="flex flex-col gap-2"
                    >
                      <div className="border border-base-color w-full p-3">
                        <input
                          type="text"
                          name="name"
                          value={inputs.name}
                          onChange={handleChange}
                          className=" appearance-none outline-none w-full text-black"
                          placeholder="Name"
                          required
                        />
                      </div>
                      <div className="border border-base-color w-full p-3">
                        <input
                          type="email"
                          name="email"
                          value={inputs.email}
                          onChange={handleChange}
                          className=" appearance-none outline-none w-full text-black"
                          placeholder="Email"
                        />
                      </div>
                      <div className="border border-base-color w-full p-3">
                        <input
                          type="text"
                          name="phone"
                          value={inputs.phone}
                          onChange={handleChange}
                          className=" appearance-none outline-none w-full text-black"
                          placeholder="Phone Number"
                        />
                      </div>
                      <div className="border border-base-color w-full p-3">
                        <input
                          type="text"
                          name="companyAddress"
                          value={inputs.companyAddress}
                          onChange={handleChange}
                          className=" appearance-none outline-none w-full text-black"
                          placeholder="Company Address "
                          required
                        />
                      </div>
                      <div className="border border-base-color w-full p-3">
                        <input
                          type="text"
                          name="productQuantity"
                          value={inputs.productQuantity}
                          onChange={handleChange}
                          className=" appearance-none outline-none w-full text-black"
                          placeholder="Product Quantity"
                          required
                        />
                      </div>
                      <div className="border border-base-color w-full p-3">
                        <input
                          type="text"
                          name="destination"
                          value={inputs.destination}
                          onChange={handleChange}
                          className=" appearance-none outline-none w-full text-black"
                          placeholder="Destination"
                          required
                        />
                      </div>
                      <div className="border border-base-color w-full p-3">
                        <textarea
                          name="message"
                          value={inputs.message}
                          onChange={handleChange}
                          className="appearance-none outline-none w-full text-black"
                          placeholder="Quoate For Rent"
                          rows={4}
                          cols={140}
                        />
                      </div>
                      <div className="form-group">
                        <button
                          type="submit"
                          className="p-4 text-white  font-semibold bg-base-color hover:bg-black hover:duration-300"
                          style={{ backgroundColor: "#13b5ea" }}
                        >
                          Submit
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
};

export default TruckRent;
